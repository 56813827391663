<template>
  <div id="axes">
    <el-form @submit.native.prevent ref="form">
      <el-collapse>
        <el-collapse-item title="X-Axis" name="0">
          <el-form-item label="Type">
            <el-select
              @change="updateChart"
              v-model="xaxis.type"
              placeholder="Select X-axis type"
            >
              <el-option
                v-for="item in xaxisType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Position">
            <el-select
              @change="updateChart"
              v-model="xaxis.position"
              placeholder="Select X-axis position"
            >
              <el-option
                v-for="item in xaxisPositions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <div v-if="xaxis.labels.formatter && xaxis.type === 'datetime'">
            <h4>Ticks</h4>
            <div class="block">
              <el-slider
                @change="updateChart"
                v-model="xaxis.tickAmount"
                :min="2"
                :max="40"
                show-input
              ></el-slider>
            </div>
          </div>

          <h4>X-axis Top Offset</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="xaxis.offsetY"
              :max="100"
              :min="-100"
              show-input
            ></el-slider>
          </div>

          <el-collapse class="mt3">
            <el-collapse-item title="X-axis Labels" name="labels">
              <el-form-item class="mt2" label="Show labels">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="xaxis.labels.show"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Formatter" v-if="xaxis.type === 'datetime'">
                <el-input
                  @change="changeXaxisFormatter('datetime')"
                  placeholder="Custom Formatter"
                  v-model="xaxisDateLabelFormatter"
                ></el-input>
              </el-form-item>

              <el-form-item label="Formatter" v-else>
                <el-input
                  @change="changeXaxisFormatter('normal')"
                  placeholder="Custom Formatter"
                  v-model="xaxisLabelFormatter"
                ></el-input>
              </el-form-item>

              <el-form-item label="Labels Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="xaxis.labels.style.colors"
                ></extended-el-color-picker>
              </el-form-item>

              <h4>Font Size</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.labels.style.fontSize"
                  :min="2"
                  :max="80"
                  show-input
                ></el-slider>
              </div>

              <h4>Font Weight</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.labels.style.fontWeight"
                  :min="100"
                  :step="100"
                  :max="900"
                ></el-slider>
              </div>

              <el-form-item class="mt2" label="Rotate">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="xaxis.labels.rotateAlways"
                ></el-switch>
              </el-form-item>

              <h4>Rotation Angle</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.labels.rotate"
                  :max="360"
                  :min="-360"
                  show-input
                ></el-slider>
              </div>

              <el-form-item class="mt2" label="Trim long labels">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="xaxis.labels.trim"
                ></el-switch>
              </el-form-item>

              <h4>Labels Left Offset</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.labels.offsetX"
                  :max="100"
                  :min="-100"
                  show-input
                ></el-slider>
              </div>

              <h4>Labels Top Offset</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.labels.offsetY"
                  :max="100"
                  :min="-100"
                  show-input
                ></el-slider>
              </div>
            </el-collapse-item>
          </el-collapse>

          <el-collapse>
            <el-collapse-item title="X-axis Title" name="title">
              <el-form-item label="Title Text">
                <el-input
                  @change="updateChart"
                  placeholder="Enter X-axis Title"
                  v-model="xaxis.title.text"
                ></el-input>
              </el-form-item>

              <el-form-item label="Title Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="xaxis.title.style.color"
                ></extended-el-color-picker>
              </el-form-item>

              <h4>Title Font Size</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.title.style.fontSize"
                  :min="2"
                  :max="80"
                  show-input
                ></el-slider>
              </div>

              <h4>Title Font Weight</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.title.style.fontWeight"
                  :min="100"
                  :step="100"
                  :max="900"
                ></el-slider>
              </div>

              <h4>Title Left Offset</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.title.offsetX"
                  :max="100"
                  :min="-100"
                  show-input
                ></el-slider>
              </div>

              <h4>Title Top Offset</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="xaxis.title.offsetY"
                  :max="100"
                  :min="-100"
                  show-input
                ></el-slider>
              </div>
            </el-collapse-item>
          </el-collapse>

          <el-collapse>
            <el-collapse-item title="Axis Ticks" name="ticks">
              <el-form-item label="Show">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="xaxis.axisTicks.show"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Ticks Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="xaxis.axisTicks.color"
                ></extended-el-color-picker>
              </el-form-item>
            </el-collapse-item>
          </el-collapse>

          <el-collapse>
            <el-collapse-item title="Axis Border" name="border">
              <el-form-item label="Show">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="xaxis.axisBorder.show"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Axis Border Color">
                <extended-el-color-picker
                  @change="updateChart"
                  v-model="xaxis.axisBorder.color"
                ></extended-el-color-picker>
              </el-form-item>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
        <el-collapse-item title="Y-Axis" name="1">
          <el-collapse>
            <el-collapse-item
              :title="`Y-axis ${index + 1}`"
              v-for="(yaxe, index) in yaxis"
              :key="index"
            >
              <el-form-item label="Show">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="yaxe.show"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Opposite">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="yaxe.opposite"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Reversed">
                <el-switch
                  inactive-color="#24272b"
                  @change="updateChart"
                  v-model="yaxe.reversed"
                ></el-switch>
              </el-form-item>

              <el-form-item label="Masking">
                <el-input
                  @change="changeYaxisFormatter(index)"
                  placeholder="Custom Formatting of labels"
                  v-model="yaxisLabelFormatters[index]"
                ></el-input>
              </el-form-item>

              <h4>Left Offset</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="yaxe.labels.offsetX"
                  :max="500"
                  :min="-100"
                  show-input
                ></el-slider>
              </div>

              <div v-if="chartType !== 'heatmap'">
                <el-form-item class="mb2 mt2" label="Lowest Val">
                  <el-input-number
                    @change="updateChart"
                    placeholder="min"
                    :max="yaxe.max - 0.1"
                    v-model="yaxe.min"
                  ></el-input-number>
                </el-form-item>

                <el-form-item class="mb2 mt2" label="Highest Val">
                  <el-input-number
                    @change="updateChart"
                    placeholder="max"
                    :min="yaxe.min + 0.1"
                    v-model="yaxe.max"
                  ></el-input-number>
                </el-form-item>
              </div>

              <h4>Tick Amount</h4>
              <div class="block">
                <el-slider
                  @change="updateChart"
                  v-model="yaxe.tickAmount"
                  :min="typeof yaxe.tickAmount !== 'undefined' ? 2 : 5"
                  :max="30"
                  show-input
                ></el-slider>
              </div>

              <el-collapse class="mt3">
                <el-collapse-item title="Y-axis Labels" name="labels">
                  <el-form-item class="mt2" label="Show labels">
                    <el-switch
                      inactive-color="#24272b"
                      @change="updateChart"
                      v-model="yaxe.labels.show"
                    ></el-switch>
                  </el-form-item>

                  <el-form-item label="Labels Color">
                    <extended-el-color-picker
                      @change="updateChart"
                      v-model="yaxe.labels.style.colors"
                    ></extended-el-color-picker>
                  </el-form-item>

                  <h4>Font Size</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.labels.style.fontSize"
                      :min="2"
                      :max="80"
                      show-input
                    ></el-slider>
                  </div>

                  <h4>Font Weight</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.labels.style.fontWeight"
                      :min="100"
                      :step="100"
                      :max="900"
                    ></el-slider>
                  </div>

                  <h4>Rotate Y-axis Labels</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.labels.rotate"
                      :max="360"
                      :min="-360"
                      show-input
                    ></el-slider>
                  </div>
                </el-collapse-item>
              </el-collapse>

              <el-collapse>
                <el-collapse-item title="Y-axis Title" name="title">
                  <el-form-item label="Text">
                    <el-input
                      @change="updateChart"
                      placeholder="Y-axis Title"
                      v-model="yaxe.title.text"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="Title Color">
                    <extended-el-color-picker
                      @change="updateChart"
                      v-model="yaxe.title.style.color"
                    ></extended-el-color-picker>
                  </el-form-item>

                  <h4>Title Font Size</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.title.style.fontSize"
                      :min="2"
                      :max="80"
                      show-input
                    ></el-slider>
                  </div>

                  <h4>Title Font Weight</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.title.style.fontWeight"
                      :min="100"
                      :step="100"
                      :max="900"
                    ></el-slider>
                  </div>

                  <h4>Title Left Offset</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.title.offsetX"
                      :max="100"
                      :min="-100"
                      show-input
                    ></el-slider>
                  </div>

                  <h4>Title Top Offset</h4>
                  <div class="block">
                    <el-slider
                      @change="updateChart"
                      v-model="yaxe.title.offsetY"
                      :max="100"
                      :min="-100"
                      show-input
                    ></el-slider>
                  </div>
                </el-collapse-item>
              </el-collapse>

              <el-collapse>
                <el-collapse-item title="Axis Ticks" name="ticks">
                  <el-form-item label="Show">
                    <el-switch
                      inactive-color="#24272b"
                      @change="updateChart"
                      v-model="yaxe.axisTicks.show"
                    ></el-switch>
                  </el-form-item>

                  <el-form-item label="Ticks Color">
                    <extended-el-color-picker
                      @change="updateChart"
                      v-model="yaxe.axisTicks.color"
                    ></extended-el-color-picker>
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>

              <el-collapse>
                <el-collapse-item title="Axis Border" name="border">
                  <el-form-item label="Show">
                    <el-switch
                      inactive-color="#24272b"
                      @change="updateChart"
                      v-model="yaxe.axisBorder.show"
                    ></el-switch>
                  </el-form-item>

                  <el-form-item label="Axis Border Color">
                    <extended-el-color-picker
                      @change="updateChart"
                      v-model="yaxe.axisBorder.color"
                    ></extended-el-color-picker>
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
        <el-collapse-item title="Grid Lines" name="2">
          <el-form-item label="Show">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="grid.show"
            ></el-switch>
          </el-form-item>

          <el-form-item label="Grid placement">
            <el-select
              @change="updateChart"
              v-model="grid.position"
              placeholder="Front/Back of the graph"
            >
              <el-option
                v-for="item in gridPositions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <h4>Line Dash</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.strokeDashArray"
              :min="0"
              :max="20"
              show-input
            ></el-slider>
          </div>

          <el-form-item class="mt2" label="Gridlines Color">
            <extended-el-color-picker
              @change="updateChart"
              v-model="grid.borderColor"
            ></extended-el-color-picker>
          </el-form-item>

          <el-form-item class="custom-colors-picker" label="Grid Row Colors">
            <extended-el-color-picker
              v-for="(color, index) in grid.row.colors"
              :key="index"
              @change="
                (val) => {
                  updateGridRowColors(val, index);
                }
              "
              :value="color"
            ></extended-el-color-picker>
            <el-button @click="appendGridRowColor" size="mini">
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              @click="clearAllGridRowColors"
              size="mini"
              title="Delete All Colors"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>

          <h4>Grid Row Color Opacity</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.row.opacity"
              :min="0"
              :max="1"
              :step="0.1"
              show-input
            ></el-slider>
          </div>

          <el-form-item
            class="custom-colors-picker mt2"
            label="Grid Column Colors"
          >
            <extended-el-color-picker
              v-for="(color, index) in grid.column.colors"
              :key="index"
              @change="
                (val) => {
                  updateGridColumnColors(val, index);
                }
              "
              :value="color"
            ></extended-el-color-picker>
            <el-button @click="appendGridColumnColor" size="mini">
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              @click="clearAllGridColumnColors"
              size="mini"
              title="Delete All Colors"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>

          <h4>Grid Column Color Opacity</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.column.opacity"
              :min="0"
              :max="1"
              :step="0.1"
              show-input
            ></el-slider>
          </div>

          <el-form-item label="Show X-axis lines">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="grid.xaxis.lines.show"
            ></el-switch>
          </el-form-item>
          <el-form-item label="Show Y-axis lines">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="grid.yaxis.lines.show"
            ></el-switch>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>

      <el-collapse>
        <el-collapse-item title="Grid Padding" name="padding">
          <h4>Top</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.padding.top"
              :min="-50"
              :max="150"
              show-input
            ></el-slider>
          </div>
          <h4>Right</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.padding.right"
              :min="-50"
              :max="150"
              show-input
            ></el-slider>
          </div>
          <h4>Bottom</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.padding.bottom"
              :min="-50"
              :max="150"
              show-input
            ></el-slider>
          </div>
          <h4>Left</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="grid.padding.left"
              :min="-50"
              :max="150"
              show-input
            ></el-slider>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import format from "number-format.js";
import dayjs from "dayjs";
import { xaxis, yaxis, grid } from "@/components/editor/chart-defaults";
import { clone } from "@/utils";

export default {
  data() {
    const clonedYaxis = clone(yaxis);
    return {
      xaxisType: [
        {
          value: "category",
          label: "category",
        },
        {
          value: "datetime",
          label: "datetime",
        },
        {
          value: "numeric",
          label: "numeric",
        },
      ],
      xaxisPositions: [
        {
          value: "bottom",
          label: "bottom",
        },
        {
          value: "top",
          label: "top",
        },
      ],
      gridPositions: [
        {
          value: "back",
          label: "back",
        },
        {
          value: "front",
          label: "front",
        },
      ],
      xaxis: Object.assign({}, xaxis),
      yaxis: clonedYaxis,
      yaxisLabelFormatters: clonedYaxis.map(() => {
        return "{value}";
      }),
      xaxisLabelFormatter: "{value}",
      xaxisDateLabelFormatter: "",
      grid: Object.assign({}, grid),
    };
  },
  watch: {
    chartConfig: function(newProp) {
      this.setNewOptions(newProp);
    },
  },
  mounted: function() {
    const opts = this.getChartConfig().w.config;
    this.setNewOptions(opts);
  },
  methods: {
    setNewOptions(opts) {
      this.xaxis = opts.xaxis;
      this.yaxis = opts.yaxis;
      this.grid = opts.grid;
    },

    changeYaxisFormatter(index) {
      this.yaxis[index].labels.formatter = (val) => {
        return this.yaxisLabelFormatters[index].replace("{value}", val);
      };
      this.updateChart();
    },
    changeXaxisFormatter(type) {
      if (this.xaxisDateLabelFormatter && type === "datetime") {
        this.xaxis.labels.formatter = (val) => {
          return dayjs(val).format(this.xaxisDateLabelFormatter);
        };
      }
      if (this.xaxisLabelFormatter && type !== "datetime") {
        this.xaxis.labels.formatter = (val) => {
          return this.xaxisLabelFormatter.replace("{value}", val);
        };
      }

      if (!this.xaxisLabelFormatter && !this.xaxisDateLabelFormatter) {
        this.xaxis.labels.formatter = undefined;
      }
      this.updateChart();
    },

    updateGridRowColors(color, index) {
      Vue.set(this.grid.row.colors, index, color);
      this.updateChart();
    },
    appendGridRowColor() {
      if (!this.grid.row.colors) {
        this.grid.row.colors = [];
      }
      this.grid.row.colors.push("#f1f2f3");
      this.updateChart();
    },
    clearAllGridRowColors: function() {
      this.grid.row.colors = undefined;
      this.updateChart();
    },
    appendGridColumnColor() {
      if (!this.grid.column.colors) {
        this.grid.column.colors = [];
      }
      this.grid.column.colors.push("#f1f2f3");
      this.updateChart();
    },
    updateGridColumnColors(color, index) {
      Vue.set(this.grid.column.colors, index, color);
      this.updateChart();
    },
    clearAllGridColumnColors: function() {
      this.grid.column.colors = undefined;
      this.updateChart();
    },

    updateChart() {
      this.updateChartOptions({
        xaxis: this.xaxis,
        yaxis: this.yaxis,
        grid: this.grid,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
